import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { IncomingMessage, ServerResponse } from 'http'
import { useAuth } from 'src/auth'
import { ENV, isClient, logger } from 'src/utils'
import { FLAG_KEYS, useGlobalFlag } from './flagr'
import { useConfig } from 'src/data/config'

export const DEFAULT_FEATURE_PATH = 'cash-bids'
export const DEFAULT_STAFF_FEATURE_PATH = 'analytics'

export const featuresAsLinks = {
  bin_samples: 'bin-samples',
  cash_bids: 'cash-bids',
  checks: 'checks',
  commodity_balances: 'commodity-balances',
  contracts: 'contracts',
  coop_investments: 'coop-investments',
  delivery_tickets: 'delivery-tickets',
  esign: 'esign',
  futures: 'futures',
  settlements: 'settlements',
  tickets: 'tickets',
  transactions: 'invoices',
  payments: 'payments',
  prepaid_contracts: 'prepaids-bookings',
  statements: 'statements',
  work_orders: 'work-orders',
  outbound_tickets: 'outbound-tickets',
}

export function useRedirectToDefaultPath() {
  const { push } = useRouter()
  const { slug, user } = useAuth()
  const isStaffFlagEnabled = useGlobalFlag(FLAG_KEYS.WEBAPP_STAFF_ROUTES)
  const { config } = useConfig()
  const landingFeature = config?.landing_feature
  const redirectPath = getUserRedirectUri({ slug, user, landingFeature, isStaffFlagEnabled })

  useEffect(() => {
    push(redirectPath)
  }, [push, redirectPath])
}

/* istanbul ignore next */
export function ClientRedirect({ path }: { path: string }) {
  const router = useRouter()

  useEffect(() => {
    router.push(path)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export function handleSsrRedirect({
  req,
  res,
  path,
  props,
}: {
  req: IncomingMessage
  res: ServerResponse
  path: string
  props: any
}) {
  logger.debug({
    message: `src/_app - redirect [path: ${path}][referer: ${req?.headers?.referer}]`,
    context: { path },
  })

  if (res && req && !isClientRouteRequest(req)) {
    res.writeHead(303, { Location: path })
    res.end()
    return
  }

  // For non-SSR requests, return `redirectToPath` which is a special
  // prop that is handled via a client-side redirect.
  return {
    ...props,
    redirectToPath: path,
  }
}

/**
 * Next can still request from the client via  `/_next/data/*`
 * Use this to detect when these types of requests are happening.
 */
export function isClientRouteRequest(req: IncomingMessage) {
  return req?.url?.indexOf('/_next/data') === 0
}

export function getUserRedirectUri({
  slug,
  user,
  nextPath,
  isStaffFlagEnabled = false,
  landingFeature,
}: {
  slug: string
  user: Me
  nextPath?: string
  // TODO: Change this back to just a `boolean` after Flagr types are improved
  isStaffFlagEnabled?: boolean | string
  landingFeature?: string
}) {
  if (nextPath) return nextPath
  if (!slug) return '/'
  if (!user && !ENV.MOCK_MODE) return ['', slug, 'auth', 'logout'].join('/')

  return isStaffFlagEnabled && user?.user_type === 'staff'
    ? [null, slug, 'staff', DEFAULT_STAFF_FEATURE_PATH].join('/')
    : [null, slug, getLandingFeaturePage(landingFeature)].join('/')
}

export function isAuthRoute() {
  return isClient() && window?.location.pathname.indexOf('/auth/') !== -1
}

/** Simple hook for determining if the user is active within a staff-level route */
export const useIsStaffPage = () => {
  const router = useRouter()
  const isStaffFlagEnabled = useGlobalFlag(FLAG_KEYS.WEBAPP_STAFF_ROUTES)
  return isStaffFlagEnabled && router.pathname.startsWith('/[company]/staff')
}

/**
 * Hook to determine the path to redirect to based on the company's landing feature.
 * @returns The relative transformed feature path to redirect to as a string
 */
export function useCompanyLandingFeaturePath() {
  const { slug } = useAuth()
  const { config } = useConfig()
  const landingFeaturePath = getLandingFeaturePage(config?.landing_feature)
  return [null, slug, landingFeaturePath].join('/')
}

export function useDefaultLandingFeature() {
  const { config } = useConfig()
  return getLandingFeaturePage(config?.landing_feature)
}

/**
 * Determine the landing feature path based on the `landingFeature` value
 * and the `featuresAsLinks` mapping. If the feature is not found, the
 * default feature path is returned.
 * @param landingFeature - The feature key from Centre
 * @returns A transformed feature page path
 * @example
 * ```tsx
 * const landingFeature = 'transactions'
 * const path = getLandingFeaturePage(landingFeature)
 * // path === 'invoices'
 * ```
 * @see featuresAsLinks
 * @see DEFAULT_FEATURE_PATH
 */
const getLandingFeaturePage = (landingFeature?: string) =>
  featuresAsLinks[landingFeature] ?? DEFAULT_FEATURE_PATH
