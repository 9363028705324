import { SERVER_ENV } from 'src/utils/env'
import { CookieJar } from 'src/utils/storage/cookies/cookie-jar'
import { COOKIE_KEYS } from 'src/utils/storage/cookies/cookies'

const TEMP_DENIED_SLUGS = ['cargillagca']

export function isTempDeniedSlug(slug: string) {
  return TEMP_DENIED_SLUGS.includes(slug)
}

export function getAccessCodeFromCookie(slug: string, cookies: CookieJar) {
  const slugCookieKey = COOKIE_KEYS.BYPASS_CODE_PREFIX + slug

  return cookies?.[slugCookieKey]
}

export function isValidAccessCode(routeSlug: string, codeFromCookie: string) {
  if (!SERVER_ENV.TEMP_SLUG_ACCESS_CODES) return false

  if (!codeFromCookie) return false

  const codesBySlug = SERVER_ENV.TEMP_SLUG_ACCESS_CODES.split(',')
    .filter((entry) => entry.length > 0)
    .reduce((acc, entry) => {
      const [slug, code] = entry.split(':')

      return !slug || !code ? acc : { ...acc, [slug]: code }
    }, {} as Record<string, string>)

  return codeFromCookie === codesBySlug[routeSlug]
}
