import {
  queryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import * as api from 'src/api'
import { queryClient } from 'src/data/queries'
import {
  InitializeCompanyRequest,
  InitializeCompanyUserRequest,
  PaymentsCompanyWalletInfo,
  PaymentsInitializeCompanyResponse,
  PaymentsInitializeCompanyUserResponse,
} from 'src/api/payments-service/company'
import {
  DeferredPaymentsResponse,
  MakePaymentResponse,
  OneTimePaymentFormBody,
  PaymentsResponse,
  PaymentSummaryResponse,
  SettlementPaymentFormBody,
  SettlementPaymentResponse,
} from 'src/api/payments-service/payments'
import {
  PaymentAccountLinkAction,
  PaymentsFundingSourceResponse,
  PaymentsGetAccountsResponse,
  PaymentsUpdateLinkedAccountsResponse,
  PaymentsWalletLinkResponse,
} from 'src/api/payments-service/users'
import {
  ExternalPaymentsFiltersForQuery,
  PaymentFiltersForQuery,
} from 'src/components/payments/PaymentFilters'
import { ExternalPaymentFiltersForQuery } from 'src/components/payments/external/ExternalPaymentFilters'
import useInvalidatePaymentsQueries from 'src/components/payments/useInvalidatePaymentQueries'
import { useMerchandiser } from 'src/data/merchandiser'
import {
  getNextPageParam,
  UseInfiniteQueryOptionsTyped,
  useInfiniteQueryTyped,
  UseMutationOptionsTyped,
  useMutationTyped,
  UseQueryOptionsTyped,
  useQueryTyped,
} from 'src/data/queries/utils'
import { isClient } from 'src/utils'

export const PAYMENTS_REFETCH_INTERVAL = Infinity

const paymentsGetUserAccountsQueryKey = 'payment-user-accounts'

interface PaymentYearSummary {
  year: string
  data: PaymentSummaryResponse
}

export function usePaymentCompanyWalletInfo(
  {
    isPaymentsEnabled,
    slug,
  }: {
    isPaymentsEnabled: boolean
    slug: string
  },
  options: Partial<UseQueryOptions<PaymentsCompanyWalletInfo, api.HTTPError>> = {}
) {
  return useQuery<PaymentsCompanyWalletInfo, api.HTTPError>({
    queryKey: ['payment-company-info', { slug }],
    queryFn: async () => api.paymentsService.paymentsPaymentCompanyWalletInfo(slug),
    enabled: isPaymentsEnabled,
    staleTime: PAYMENTS_REFETCH_INTERVAL,
    refetchInterval: PAYMENTS_REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
    meta: {
      errorMessage:
        "Unable to retrieve Company's Bushel Wallet Info, please refresh the page to try again.",
    },
    ...options,
  })
}

const paymentWalletStatusOptions = ({ bushelId }: { bushelId: string }) =>
  queryOptions({
    queryKey: generateWalletStatusQueryKey(bushelId),
    queryFn: () => {
      return api.paymentsService.paymentsUserWalletStatus(bushelId)
    },
    refetchOnWindowFocus: true,
    meta: {
      errorMessage:
        'Unable to retrieve Bushel Wallet status, please refresh the page to try again.',
    },
    select: (data) => ({
      userWalletInfo: data?.[0],
      isOnboarded: data?.[0]?.onboarded,
      isTransactable: data?.[0]?.transactable,
    }),
  })

type IPaymentWalletStatusOptions = typeof paymentWalletStatusOptions
type PaymentWalletStatusOptionsParams = Parameters<IPaymentWalletStatusOptions>[0]
type PaymentWalletStatusOptions = Partial<ReturnType<IPaymentWalletStatusOptions>>

export function generateWalletStatusQueryKey(bushelId: string): (string | { bushelId: string })[] {
  return ['payment-user-wallet-status', { bushelId }]
}

export function usePaymentWalletStatus(
  { bushelId }: PaymentWalletStatusOptionsParams,
  options: PaymentWalletStatusOptions = {}
) {
  return useQuery({
    ...paymentWalletStatusOptions({ bushelId }),
    ...options,
  })
}

/**
 * Fetches the relative Bushel Wallet Link for the user using the current
 * window location as the redirect URL.
 * @param options - Overwrite any query options
 * @returns Query object for the Bushel Wallet Link
 */
export function usePaymentWalletLink(
  options: UseQueryOptionsTyped<PaymentsWalletLinkResponse> = {}
) {
  const redirectURL = isClient() ? `${window.location.origin}/please-close` : ''

  return useQueryTyped({
    queryKey: ['payment-user-wallet-link', { redirectURL }],
    queryFn: () => api.paymentsService.paymentsUserWalletLink(redirectURL),
    refetchOnWindowFocus: true,
    meta: {
      errorMessage: 'Unable to retrieve Bushel Wallet Link, please refresh the page to try again.',
    },
    enabled: 'enabled' in options ? options.enabled : !!redirectURL,
    ...options,
  })
}

export function usePaymentsUserAccounts(
  options: UseQueryOptionsTyped<PaymentsGetAccountsResponse> = {}
) {
  return useQueryTyped({
    queryKey: [paymentsGetUserAccountsQueryKey],
    queryFn: () => api.paymentsService.paymentsUserGetAccounts(),
    refetchOnWindowFocus: true,
    meta: {
      errorMessage: 'Unable to retrieve Payment accounts, please refresh the page to try again.',
    },
    ...options,
  })
}

export function usePaymentsUserFundingSources(
  options: UseQueryOptionsTyped<PaymentsFundingSourceResponse> = {}
) {
  return useQueryTyped({
    queryKey: ['payment-user-funding-source'],
    queryFn: () => api.paymentsService.paymentsUserFundingSources(),
    refetchOnWindowFocus: true,
    meta: {
      errorMessage: 'Unable to retrieve funding sources, please try again.',
    },
    ...options,
  })
}

export function usePaymentsUpdateLinkedAccounts(
  accountId: string,
  agreementLanguage: string,
  options: UseMutationOptionsTyped<
    PaymentsUpdateLinkedAccountsResponse,
    PaymentAccountLinkAction,
    { previousState: PaymentsGetAccountsResponse }
  > = {}
) {
  return useMutationTyped({
    mutationFn: (action) =>
      api.paymentsService.paymentsUserUpdateLinkedAccounts(accountId, agreementLanguage, action),
    onMutate: () => {
      const previousState: PaymentsGetAccountsResponse =
        queryClient.getQueryData([paymentsGetUserAccountsQueryKey]) ?? []

      return { previousState }
    },
    onSettled: async (_response, _error, action, ctx) => {
      if (ctx?.previousState) {
        queryClient.setQueryData(
          [paymentsGetUserAccountsQueryKey],
          ctx.previousState.map((account) =>
            account.id === accountId
              ? {
                  ...account,
                  isLinked: action === 'LINK',
                  isLinkedToCurrentUser: action === 'LINK',
                }
              : account
          )
        )
      }

      await queryClient.invalidateQueries({ queryKey: [paymentsGetUserAccountsQueryKey] })
    },
    ...options,
  })
}

export function usePayments(
  {
    filter,
    enabled,
    pageSize = 10,
    userId,
  }: {
    filter?: PaymentFiltersForQuery
    pageSize?: number
    enabled?: boolean
    userId?: number
  } = {},
  options: UseInfiniteQueryOptionsTyped<PaymentsResponse> = {}
) {
  const query = useInfiniteQueryTyped({
    queryKey: ['payments', { filter, userId, pageSize }],
    queryFn: ({ pageParam }) =>
      api.paymentsService.paymentList({ filter, page: pageParam, size: pageSize }),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve payments, please refresh the page to try again.',
      isAlertable: (error: api.HTTPError) => error.response?.status !== 403,
    },
    getNextPageParam,
    enabled,
    ...options,
  })

  const mergedPageData = useMemo<Payment[]>(() => {
    // Infinite queries contain an array of page data
    // This reduces them down to just one big list of the items
    return (query.data?.pages || []).reduce((acc, page) => [...acc, ...page.data], [])
  }, [query.data?.pages])

  return { query, data: mergedPageData }
}

export function useDeferredPayments(
  {
    filter,
    sort,
    enabled,
  }: {
    filter?: PaymentFiltersForQuery
    sort?: { enabled?: boolean; direction?: string; field?: string }
    enabled?: boolean
  } = {},
  options: UseInfiniteQueryOptionsTyped<DeferredPaymentsResponse> = {}
) {
  const query = useInfiniteQueryTyped({
    queryKey: ['deferred-payments', { filter, sort }],
    queryFn: ({ pageParam }) =>
      api.paymentsService.deferredPaymentList({
        filter,
        sort,
        page: { page: pageParam, size: 10 },
      }),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve payments, please refresh the page to try again.',
    },
    getNextPageParam,
    enabled,
    ...options,
  })

  const mergedPageData = useMemo<DeferredPayment[]>(() => {
    // Infinite queries contain an array of page data
    // This reduces them down to just one big list of the items
    return (query.data?.pages || []).reduce((acc, page) => [...acc, ...page.data], [])
  }, [query.data?.pages])

  return { query, data: mergedPageData }
}

export function useDeferredPaymentDetail(
  { id }: { id: string },
  options: UseQueryOptionsTyped<DeferredPaymentDetail> = {}
) {
  return useQueryTyped<DeferredPaymentDetail, api.HTTPError>({
    queryKey: ['deferred-payment-detail', { id }],
    queryFn: () => api.paymentsService.deferredPaymentDetail({ id }),
    staleTime: PAYMENTS_REFETCH_INTERVAL,
    refetchInterval: PAYMENTS_REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
    ...options,
  })
}

type UpdateDeferredPaymentBody = {
  date: string
  amount?: string
  memo?: string
}

export function useUpdateDeferredPayment(
  { id }: { id: string },
  options: UseMutationOptionsTyped<any, UpdateDeferredPaymentBody> = {}
) {
  return useMutationTyped({
    mutationFn: (body) => api.paymentsService.updateDeferredPayment({ id, body }),
    ...options,
  })
}

export function useDeleteDeferredPayment({
  id,
  onError,
}: {
  id: string
  onError?: (args: api.HTTPError) => void
}) {
  const invalidatePaymentsQueries = useInvalidatePaymentsQueries()
  // if good, api returns 200 with empty body
  return useMutation<unknown, api.HTTPError>({
    mutationKey: ['delete-deferred-payment', { id }],
    mutationFn: () => {
      return api.paymentsService.deleteDeferredPayment({ id })
    },
    onSuccess: invalidatePaymentsQueries,
    onError,
  })
}

export function usePaymentSummary({
  filter,
  enabled,
}: {
  filter?: PaymentFiltersForQuery
  enabled?: boolean
} = {}) {
  return useQuery<PaymentYearSummary[], api.HTTPError>({
    queryKey: ['payment-summary', { filter }, filter?.dates?.to, filter?.dates?.from],
    queryFn: async () => {
      const currentYear = new Date().getFullYear()
      const lastThreeYears = [currentYear, currentYear - 1, currentYear - 2]

      if (filter?.dates?.to && filter?.dates?.from) {
        const filteredSummary = await api.paymentsService.paymentSummary({ filter })
        const filteredResponse: PaymentYearSummary = { year: 'filtered', data: filteredSummary }
        return [filteredResponse]
      }

      const response = await Promise.all(
        lastThreeYears.map(async (year) => {
          const summaryDates = {
            from: dayjs()
              .set('year', year)
              .set('month', 0)
              .set('date', 1)
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0)
              .set('millisecond', 0)
              .toISOString(),
            to: dayjs()
              .set('year', year)
              .set('month', 11)
              .set('date', 31)
              .set('hour', 23)
              .set('minute', 59)
              .set('second', 59)
              .set('millisecond', 999)
              .toISOString(),
          }
          const yearSummary = await api.paymentsService.paymentSummary({ filter }, summaryDates)
          const yearResponse: PaymentYearSummary = { year: year.toString(), data: yearSummary }

          return yearResponse
        })
      )

      return response
    },
    meta: {
      errorMessage: 'Unable to retrieve payment summaries, please refresh the page to try again.',
    },
    staleTime: PAYMENTS_REFETCH_INTERVAL,
    refetchInterval: PAYMENTS_REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
    enabled,
  })
}

// ** NON-WALLET TRANSACTIONS ** //
export function useDeferredPaymentList({
  filter,
}: {
  filter?: ExternalPaymentFiltersForQuery
} = {}) {
  const { selectedUserId } = useMerchandiser()

  const query = useInfiniteQuery({
    queryKey: ['external-deferred-payment-list', { filter, selectedUserId }],
    queryFn: ({ pageParam }) =>
      api.paymentsService.deferredPaymentV2List({
        filter,
        page: pageParam,
        size: 10,
      }),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve payments, please refresh the page to try again.',
    },
    getNextPageParam,
  })

  const mergedPageData = useMemo<DeferredPaymentV2[]>(() => {
    // Infinite queries contain an array of page data
    // This reduces them down to just one big list of the items
    return (query.data?.pages || []).reduce((acc, page) => [...acc, ...page.data], [])
  }, [query.data?.pages])

  return { query, data: mergedPageData }
}

export function useBushelDeferredPaymentDetail({
  id,
  enabled,
}: {
  id: string | number
  enabled: boolean
}) {
  return useQuery<BushelDeferredPaymentDetail, api.HTTPError>({
    queryKey: ['bushel-deferred-payment-detail', { id }],
    queryFn: async () => {
      return api.paymentsService.bushelDeferredPaymentDetail(id)
    },
    enabled,
    staleTime: Infinity,
  })
}

export function useExternalDeferredPaymentDetail({
  id,
  enabled = true,
}: {
  id: string | number
  enabled?: boolean
}) {
  return useQuery<ExternalDeferredPaymentDetail, api.HTTPError>({
    queryKey: ['external-deferred-payment-detail', { id }],
    queryFn: async () => {
      return api.paymentsService.externalDeferredPaymentDetail(id)
    },
    enabled,
    staleTime: Infinity,
  })
}

export function useExternalHistoricalPaymentList({
  filter,
}: {
  filter?: ExternalPaymentFiltersForQuery
} = {}) {
  const { selectedUserId } = useMerchandiser()

  const query = useInfiniteQuery({
    queryKey: ['external-historical-payment-list', { filter, selectedUserId }],
    queryFn: ({ pageParam }) =>
      api.paymentsService.historicalPaymentV2List({
        filter,
        page: pageParam,
        size: 10,
      }),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve payments, please refresh the page to try again.',
    },
    getNextPageParam,
  })

  const mergedPageData = useMemo<HistoricalPaymentV2[]>(() => {
    // Infinite queries contain an array of page data
    // This reduces them down to just one big list of the items
    return (query.data?.pages || []).reduce((acc, page) => [...acc, ...page.data], [])
  }, [query.data?.pages])

  return { query, data: mergedPageData }
}

export function useBushelHistoricalPaymentDetail({
  id,
  enabled,
}: {
  id: string | number
  enabled: boolean
}) {
  return useQuery<BushelHistoricalPaymentDetail, api.HTTPError>({
    queryKey: ['bushel-historical-payment-detail', { id }],
    queryFn: () => api.paymentsService.bushelHistoricalPaymentDetail(id),
    enabled,
    staleTime: Infinity,
  })
}

export function useExternalHistoricalPaymentDetail({
  id,
  enabled = true,
}: {
  id: string | number
  enabled?: boolean
}) {
  return useQuery<ExternalHistoricalPaymentDetail, api.HTTPError>({
    queryKey: ['external-historical-payment-detail', { id }],
    queryFn: () => api.paymentsService.externalHistoricalPaymentDetail(id),
    enabled,
    staleTime: Infinity,
  })
}

export function useExternalPaymentAssociatedSettlements({
  origin,
  id,
}: {
  origin: string
  id: string | number
}) {
  return useQuery<ExternalPaymentAssociatedSettlement[], api.HTTPError>({
    queryKey: ['external-payment-associated-settlements', { id, origin }],
    queryFn: () => api.paymentsService.externalAssociatedSettlements({ id, origin }),
    staleTime: Infinity,
  })
}

export function useExternalPaymentAssociatedInvoices({
  origin,
  id,
}: {
  origin: string
  id: string | number
}) {
  return useQuery<ExternalPaymentAssociatedInvoice[], api.HTTPError>({
    queryKey: ['external-payment-associated-invoices', { id, origin }],
    queryFn: () => api.paymentsService.externalAssociatedInvoices({ id, origin }),
    staleTime: Infinity,
  })
}

export function useExternalPaymentSummary({
  filter,
}: {
  filter?: ExternalPaymentsFiltersForQuery
}) {
  const { selectedUserId } = useMerchandiser()

  return useQuery({
    queryKey: ['external-payment-summary', { filter, selectedUserId }],
    queryFn: async () => {
      const summaryDates = {
        from: dayjs().subtract(2, 'year').startOf('year').toISOString(),
        to: dayjs().endOf('year').toISOString(),
      }
      return api.paymentsService.externalPaymentSummary({ filter }, summaryDates)
    },
    meta: {
      errorMessage: 'Unable to retrieve payment summaries, please refresh the page to try again.',
    },
  })
}

export function useExternalPaymentsFilterOptions({ enabled = true }: { enabled?: boolean }) {
  const { selectedUserId } = useMerchandiser()

  return useQuery<ExternalPaymentFilterOptions>({
    queryKey: ['external-payment-filter-options', { selectedUserId }],
    queryFn: () => api.paymentsService.externalPaymentFilters(),
    enabled,
    staleTime: Infinity,
  })
}

// ** STAFF PAYMENTS ** //
/**
 * Registers the user with the payments backend and returns a status based
 * on if the user is already registered or not.
 * @param body - Body of the request
 * @param options - Additional query options
 * @see https://payments-back-end-qa-company.dev.bushelops.com/swagger-ui/index.html#/company/insertCompanyUser
 */
export const useInitializePaymentUser = (
  body: InitializeCompanyUserRequest,
  options: UseQueryOptionsTyped<PaymentsInitializeCompanyUserResponse> = {}
) =>
  useQueryTyped({
    queryKey: ['initialize-payment-user', { companyUserId: body.companyUserId }, body],
    queryFn: () => api.paymentsService.paymentsInitializeCompanyUser(body),
    gcTime: Infinity,
    meta: {
      errorMessage:
        'There was a problem initializing. Please try again or contact your administrator.',
    },
    ...options,
  })

/**
 * Handles the creation of the company record. Typically in response to a COMPANY_NOT_FOUND
 * error from the 'payments-initialize-user' query.
 * @param companySlug - Company slug to initialize payments for
 * @param options - Additional query options
 * @returns Company information query
 * @see https://payments-back-end-qa-company.dev.bushelops.com/swagger-ui/index.html#/company/insertCompanyInformation
 * @see useInitializePaymentUser
 */
export const useInitializePaymentCompany = (
  options: UseMutationOptionsTyped<PaymentsInitializeCompanyResponse, InitializeCompanyRequest> = {}
) =>
  useMutationTyped({
    mutationFn: (body) => api.paymentsService.paymentsInitializeCompany(body),
    meta: {
      errorMessage:
        'There was a problem initializing. Please try again or contact your administrator.',
    },
    ...options,
  })

/**
 * Handles the submission of a settlement payment.
 * @param body - New settlement payment request body
 * @param options - Additional mutation options
 * @returns Mutation object for submitting a settlement payment
 * @see https://payments-back-end-qa-payments.dev.bushelops.com/swagger-ui/index.html#/Payments/createSettlementPayment
 */
export const useMakeSettlementPayment = (
  options: UseMutationOptionsTyped<SettlementPaymentResponse, SettlementPaymentFormBody> = {}
) =>
  useMutationTyped({
    mutationFn: (body) => api.paymentsService.makeSettlementPayment(body),
    ...options,
  })

/**
 * Handles the submission of a one-time payment.
 * @param body - New one-time payment request body
 * @param options - Additional mutation options
 * @returns Mutation object for submitting a one-time payment
 * @see https://payments-back-end-qa-payments.dev.bushelops.com/swagger-ui/index.html#/Payments/createOnetimePayment
 */
export const useMakeOneTimePayment = (
  options: UseMutationOptionsTyped<MakePaymentResponse, OneTimePaymentFormBody> = {}
) =>
  useMutationTyped({
    mutationFn: (body) => api.paymentsService.makeOneTimePayment(body),
    ...options,
  })
