import useMeasure, { UseMeasureRef } from 'react-use/lib/useMeasure'
import RectangleInPixels from 'src/components/designsystem/measurements/rectangle-in-pixels'

export default function useMeasureInPixels<E extends Element = Element>(): [
  UseMeasureRef<E>,
  RectangleInPixels
] {
  const [reference, { x, y, top, left, right, bottom, height, width }] = useMeasure()

  return [
    reference,
    {
      x: `${x}px`,
      y: `${y}px`,
      left: `${left}px`,
      right: `${right}px`,
      top: `${top}px`,
      bottom: `${bottom}px`,
      height: `${height}px`,
      width: `${width}px`,
    },
  ]
}
