import {
  useCallback,
  useContext,
  createContext,
  useRef,
  useState,
  HTMLAttributes,
  useEffect,
  useMemo,
} from 'react'
import { SystemStyleObject } from '@chakra-ui/styled-system'
import dayjs, { Dayjs } from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import localeEn from 'dayjs/locale/en'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { splitIntoWeeks, makeMonthArray, calculateSameDayOfDifferentMonth } from './utils'

dayjs.extend(localeData)
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)

dayjs.locale('en')

// TYPES

export type SingleValue = Dayjs | string | null
export type RangeValue = [SingleValue, SingleValue]

interface DayProps extends HTMLAttributes<HTMLElement> {
  key: string
  ref: (element: HTMLElement | null) => void
}

export enum SelectionType {
  start = 'start',

  end = 'end',
  middle = 'middle',
  only = 'only',
  unselected = 'unselected',
}

export interface Day {
  date: Dayjs
  number: number
  props: DayProps
  isSelected: boolean
  isWeekend: boolean
  isToday: boolean
  isFocused: boolean
  selectionType: SelectionType
}

export interface Month {
  key: string
  firstDay: Dayjs
  weeks: (Day | null)[][]
  weekdays: string[]
}

export enum DatepickerMode {
  single = 'single',
  range = 'range',
}

export interface RangePreset {
  label: string
  'aria-label': string
  value: [Dayjs, Dayjs] | (() => [Dayjs, Dayjs])
}

// UTILS

function getKey(date: Dayjs): string {
  return date.format('YYYY-MM-DD')
}

function getWeeksForMonth(date: Dayjs) {
  const daysInMonth = makeMonthArray(date)
  const weeks = splitIntoWeeks(daysInMonth)
  return weeks
}

// HOOKS

interface DatepickerOptionsBase {
  mode: DatepickerMode
  numberOfMonths?: number
  locale?: ILocale
  currentView?: {
    currentViewDate: Dayjs
    setCurrentView: React.Dispatch<React.SetStateAction<Dayjs>>
  }
}

interface SingleDatepickerOptions extends DatepickerOptionsBase {
  mode: DatepickerMode.single
  value: SingleValue
  onChange: (date: Dayjs) => void
}

interface RangeDatepickerOptions extends DatepickerOptionsBase {
  mode: DatepickerMode.range
  value: RangeValue
  onChange: (range: [Dayjs, Dayjs | null]) => void
}

export type DatepickerOptions = SingleDatepickerOptions | RangeDatepickerOptions

export interface DatepickerResult {
  months: Month[]
  nextMonthProps: { onClick: () => void; 'aria-label': string }
  previousMonthProps: { onClick: () => void; 'aria-label': string }
  yearSelectProps: {
    value: number
    onChange: Exclude<HTMLAttributes<HTMLSelectElement>['onChange'], undefined>
  }
  monthSelectProps: {
    value: number
    onChange: Exclude<HTMLAttributes<HTMLSelectElement>['onChange'], undefined>
  }
  currentView: dayjs.Dayjs
  setCurrentView: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>
  localeData: any
}

export const useDatepicker = (options: DatepickerOptions): DatepickerResult => {
  const locale: ILocale = options.locale ?? localeEn
  const localeData = dayjs().locale(locale).localeData()

  const numberOfMonths = options.numberOfMonths ?? 1

  const defaultDay = dayjs(
    (options.mode === DatepickerMode.single ? options.value : options.value[0]) ?? undefined
  )
  const [internalCurrentView, setInternalCurrentView] = useState(defaultDay)

  const setCurrentView = options.currentView
    ? options.currentView.setCurrentView
    : setInternalCurrentView
  const currentView = options.currentView
    ? options.currentView.currentViewDate
    : internalCurrentView

  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null)
  const [focusedDay, setFocusedDay] = useState<Dayjs | null>(null)
  const dayRefs = useRef<{ [key: string]: HTMLElement }>({})

  useEffect(() => {
    if (focusedDay) {
      if (!focusedDay.isSame(currentView, 'month')) {
        setCurrentView(currentView.month(focusedDay.month()).year(focusedDay.year()))
      }
      const element = dayRefs.current[getKey(focusedDay)]
      element?.focus()
    }
  }, [focusedDay, setCurrentView, currentView])

  const months = new Array(numberOfMonths)
    .fill(null)
    .map((_, index) => currentView.month(currentView.month() + index).date(1))

  const previousMonth = useCallback(
    (advanceBy = 1) => {
      setCurrentView((date) => date.subtract(advanceBy, 'month'))
      setFocusedDay(null)
    },
    [setCurrentView, setFocusedDay]
  )

  const nextMonth = useCallback(
    (advanceBy = 1) => {
      setCurrentView((date) => date.add(advanceBy, 'month'))
      setFocusedDay(null)
    },
    [setCurrentView, setFocusedDay]
  )

  const monthRange = [currentView.month(), currentView.month() + numberOfMonths - 1]

  function isInMonthRange(date: Dayjs) {
    const month = date.month()
    const result = month >= monthRange[0] && month <= monthRange[1]
    return result
  }

  function makeDayProps(date: Dayjs, isSelected: boolean): DayProps {
    function calculateTabIndex() {
      const defaultFocus = date.isSame(currentView.startOf('month'), 'day') ? 0 : -1

      if (focusedDay) {
        if (isInMonthRange(focusedDay)) {
          return focusedDay.isSame(date, 'day') ? 0 : -1
        }
        return defaultFocus
      }
      if (defaultDay) {
        if (isInMonthRange(defaultDay)) {
          return defaultDay.isSame(date, 'day') ? 0 : -1
        }
        return defaultFocus
      }
      return defaultFocus
    }

    return {
      key: date.format('YYYY-MM-DD'),
      tabIndex: calculateTabIndex(),
      ref: (element) => {
        if (element) {
          dayRefs.current[getKey(date)] = element
        }
      },
      role: 'button',
      'aria-label': isSelected
        ? `Selected date. ${date.format('MMMM D, YYYY')}`
        : date.format('MMMM D, YYYY'),
      'aria-selected': isSelected ? 'true' : 'false',
      onClick: () => {
        if (options.mode === DatepickerMode.single) {
          options.onChange(date)
          return
        }

        if (options.value[1]) {
          // Has existing selection, start new
          options.onChange([date, null])
          return
        }

        if (options.value[0]) {
          // Making selection, attempt setting end
          if (date.isAfter(options.value[0], 'date') || date.isSame(options.value[0], 'date')) {
            // Accept change if second-selected date is newer/same
            options.onChange([dayjs(options.value[0]), date])
          }
          return
        }

        // No existing selection, start new
        options.onChange([date, null])
      },
      onKeyUp: (event) => {
        let targetDate = date
        if (event.key === 'ArrowRight') {
          targetDate = date.add(1, 'day')
        }
        if (event.key === 'ArrowLeft') {
          targetDate = date.subtract(1, 'day')
        }
        if (event.key === 'ArrowUp') {
          targetDate = date.subtract(1, 'week')
        }
        if (event.key === 'ArrowDown') {
          targetDate = date.add(1, 'week')
        }
        if (event.key === 'Home') {
          targetDate = date.startOf('week')
        }
        if (event.key === 'End') {
          targetDate = date.endOf('week')
        }
        if (event.key === 'PageUp') {
          targetDate = calculateSameDayOfDifferentMonth(
            date,
            date.subtract(1, event.shiftKey ? 'year' : 'month')
          )
          previousMonth(event.shiftKey ? 12 : 1)
        }
        if (event.key === 'PageDown') {
          targetDate = calculateSameDayOfDifferentMonth(
            date,
            date.add(1, event.shiftKey ? 'year' : 'month')
          )
          nextMonth(event.shiftKey ? 12 : 1)
        }
        setFocusedDay(targetDate)
      },
      onMouseEnter: () => {
        setHoveredDay(date)
      },
      onFocus: () => {
        setHoveredDay(date)
      },
    }
  }

  function getSelectionType(date: Dayjs): SelectionType {
    if (!options.value) return SelectionType.unselected

    if (options.mode === 'single') {
      if (options.value) {
        if (date.isSame(dayjs(options.value), 'date')) {
          return SelectionType.only
        }
        return SelectionType.unselected
      }
      if (hoveredDay) {
        if (date.isSame(hoveredDay, 'date')) {
          return SelectionType.only
        }
        return SelectionType.unselected
      }
    } else {
      const [start, end] = options.value
      if (!start && !end) {
        return SelectionType.unselected
      }
      if (start && end) {
        if (date.isAfter(dayjs(start), 'date') && date.isBefore(dayjs(end), 'date')) {
          return SelectionType.middle
        }
        if (date.isSame(dayjs(start), 'date')) {
          if (dayjs(start).isSame(dayjs(end), 'date')) return SelectionType.only
          return SelectionType.start
        }
        if (date.isSame(dayjs(end), 'date')) return SelectionType.end
        return SelectionType.unselected
      }
      if (hoveredDay) {
        if (date.isAfter(dayjs(start ?? undefined), 'date') && date.isBefore(hoveredDay, 'date')) {
          return SelectionType.middle
        }
        if (date.isSame(dayjs(start ?? undefined), 'date')) {
          if (dayjs(start ?? undefined).isSame(hoveredDay, 'date')) return SelectionType.only
          return SelectionType.start
        }
        if (date.isAfter(dayjs(start ?? undefined), 'date') && date.isSame(hoveredDay, 'date')) {
          return SelectionType.end
        }
        return SelectionType.unselected
      }
      if (start) {
        if (date.isSame(dayjs(options.value[0] ?? undefined), 'date')) {
          return SelectionType.start
        }
        return SelectionType.unselected
      }
    }
    return SelectionType.unselected
  }

  return {
    currentView,
    setCurrentView,
    localeData,
    previousMonthProps: {
      onClick: () => previousMonth(),
      'aria-label': 'Previous month',
    },
    nextMonthProps: {
      onClick: () => nextMonth(),
      'aria-label': 'Next month',
    },
    yearSelectProps: {
      value: currentView?.year(),
      onChange: (e) => setCurrentView(currentView?.year(Number(e.currentTarget.value))),
    },
    monthSelectProps: {
      value: currentView?.month(),
      onChange: (e) => setCurrentView(currentView?.month(Number(e.currentTarget.value))),
    },
    months: months.map((firstDateOfMonth) => {
      const weeks = getWeeksForMonth(firstDateOfMonth)

      return {
        key: firstDateOfMonth.format('MM-YYYY'),
        firstDay: firstDateOfMonth.locale(locale),
        weekdays: localeData.weekdaysShort(),
        weeks: weeks.map((week) =>
          week.map((date) => {
            if (!date) return null

            const selectionType = getSelectionType(date)
            const isSelected = selectionType !== SelectionType.unselected
            return {
              date,
              number: date.date(),
              props: makeDayProps(date, isSelected),
              isSelected,
              isToday: date.isSame(dayjs(), 'day'),
              isWeekend: [0, 6].includes(date.day()),
              isFocused: date.isSame(dayjs(focusedDay ?? undefined), 'day'),
              selectionType,
            }
          })
        ),
      }
    }),
  }
}

export function useDatePickerPopover() {
  const triggerRef = useRef(null)
  const contentRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const open = useCallback(() => setIsOpen(!isOpen), [isOpen])
  const close = useCallback(() => setIsOpen(false), [])
  const outsideClickCallback = useCallback(() => close(), [close])

  useOnClickOutside([triggerRef, contentRef], outsideClickCallback)

  return {
    triggerRef,
    contentRef,
    isOpen,
    setIsOpen,
    open,
    close,
  }
}

function useOnClickOutside(refs, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Switch to returning `true` if click found inside one of the refs
      const isClickInsideRef = refs.reduce(
        (acc, ref) => (!ref.current || ref.current.contains(event.target) ? true : acc),
        false
      )

      // Do nothing if clicking ref's element or descendent elements
      if (isClickInsideRef) return

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [refs, handler])
}

interface UseDateInputOptions {
  format?: string
  initialValue?: SingleValue
}
interface UseDateInputResult {
  date: Dayjs | null
  setDate: (date: SingleValue) => void
  dateString: string
  setDateString: (date: string) => void
  inputProps: {
    onBlur: Exclude<HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>['onBlur'], undefined>
  }
}

export const useDateInput = (options: UseDateInputOptions = {}): UseDateInputResult => {
  const format = options.format ?? 'MM/DD/YYYY'

  const [date, setDate] = useState<Dayjs | null>(() => dayjs(options.initialValue ?? undefined))
  const [dateString, setDateString] = useState<string>(
    () => dayjs(options.initialValue ?? undefined).format(format) ?? ''
  )

  const handleSetDate = useCallback(
    (date) => {
      setDate(date)
      setDateString(date?.format(format) ?? '')
    },
    [setDate, setDateString, format]
  )

  const handleSetDateString = useCallback(
    (dateString) => {
      setDateString(dateString)
      const parsed = dayjs(dateString, format)
      if (parsed.isValid()) {
        setDate(parsed)
      }
    },
    [setDate, setDateString, format]
  )

  const onBlur = useCallback<UseDateInputResult['inputProps']['onBlur']>(() => {
    if (!dayjs(dateString, format).isValid() && date) {
      handleSetDateString(date.format(format))
    }
  }, [dateString, format, date, handleSetDateString])

  return {
    date,
    setDate: handleSetDate,
    dateString,
    setDateString: handleSetDateString,
    inputProps: {
      onBlur,
    },
  }
}

interface UseDateRangeOptions {
  format?: string
  initialValue?: [Dayjs | null, Dayjs | null]
}
interface UseDateRangeResult {
  range: [Dayjs | null, Dayjs | null]
  setRange: (range: RangeValue) => void
  dateString: [string, string]
  setRangeStart: (date: SingleValue) => void
  setRangeEnd: (date: SingleValue) => void
  setRangeStartString: (date: string) => void
  setRangeEndString: (date: string) => void
  inputProps: {
    start: {
      onBlur: Exclude<HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>['onBlur'], undefined>
      onChange: Exclude<
        HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>['onChange'],
        undefined
      >
    }
    end: {
      onBlur: Exclude<HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>['onBlur'], undefined>
      onChange: Exclude<
        HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>['onChange'],
        undefined
      >
    }
  }
}

export const useDateRange = (options: UseDateRangeOptions = {}): UseDateRangeResult => {
  const format = options.format ?? 'MM/DD/YYYY'

  const [range, setRange] = useState<[Dayjs | null, Dayjs | null]>(
    () => options.initialValue ?? [null, null]
  )
  const [dateString, setDateString] = useState<[string, string]>(() => [
    options?.initialValue?.[0] ? dayjs(options?.initialValue?.[0] ?? undefined).format(format) : '',
    options?.initialValue?.[1] ? dayjs(options?.initialValue?.[1] ?? undefined).format(format) : '',
  ])

  const handleSetRange = useCallback(
    (range) => {
      setRange(range)
      setDateString([range?.[0]?.format(format) ?? '', range?.[1]?.format(format) ?? ''])
    },
    [setRange, setDateString, format]
  )

  // SET RANGE DATES

  const handleSetRangeStart = useCallback(
    (date) => {
      setRange((state) => [date, state[1]])
      setDateString((state) => [date?.format(format) ?? '', state[1]])
    },
    [setRange, setDateString, format]
  )

  const handleSetRangeEnd = useCallback(
    (date) => {
      setRange((state) => [state[0], date])
      setDateString((state) => [state[0], date?.format(format) ?? ''])
    },
    [setRange, setDateString, format]
  )

  // SET RANGE STRINGS

  const handleSetRangeStartString = useCallback(
    (dateString) => {
      setDateString((state) => [dateString, state[1]])
      const parsed = dayjs(dateString, format)
      if (parsed.isValid()) {
        handleSetRangeStart(parsed)
      }
    },
    [handleSetRangeStart, setDateString, format]
  )

  const handleSetRangeEndString = useCallback(
    (dateString) => {
      setDateString((state) => [state[0], dateString])
      const parsed = dayjs(dateString, format)
      if (parsed.isValid()) {
        handleSetRangeEnd(parsed)
      }
    },
    [handleSetRangeEnd, setDateString, format]
  )

  // INPUT PROPS

  const onStartBlur = useCallback<UseDateInputResult['inputProps']['onBlur']>(() => {
    if (!dayjs(dateString[0], format).isValid() && range[0]) {
      handleSetRangeStartString(range?.[0]?.format(format))
    }
  }, [dateString, format, range, handleSetRangeStartString])

  const onEndBlur = useCallback<UseDateInputResult['inputProps']['onBlur']>(() => {
    if (!dayjs(dateString[1], format).isValid() && range[1]) {
      handleSetRangeEndString(range?.[1]?.format(format))
    }
  }, [dateString, format, range, handleSetRangeEndString])

  return {
    range,
    setRange: handleSetRange,
    dateString,
    setRangeStart: handleSetRangeStart,
    setRangeEnd: handleSetRangeEnd,
    setRangeStartString: handleSetRangeStartString,
    setRangeEndString: handleSetRangeEndString,
    inputProps: {
      start: {
        onChange: (e) => handleSetRangeStartString(e.currentTarget.value),
        onBlur: onStartBlur,
      },
      end: {
        onBlur: onEndBlur,
        onChange: (e) => handleSetRangeEndString(e.currentTarget.value),
      },
    },
  }
}

// Helper Components

interface MonthGridContextValue {
  month: Month
}
const MonthGridContext = createContext<MonthGridContextValue | null>(null)

export interface MonthGridProps {
  month: Month
  children?: React.ReactNode
}

export function MonthGrid(props: MonthGridProps) {
  const monthContext = useMemo(() => ({ month: props.month }), [props.month])
  return (
    <MonthGridContext.Provider value={monthContext}>
      <table style={{ borderSpacing: '0 4px' }} cellSpacing={0}>
        {props.children}
      </table>
    </MonthGridContext.Provider>
  )
}

interface DaysProps {
  children: (day: Day) => React.ReactNode
}

export function Days(props: DaysProps) {
  const monthGridContext = useContext(MonthGridContext)

  if (!monthGridContext && process.env.NODE_ENV === 'development') {
    throw new Error('Must use <Day /> component inside of a <MonthGrid />')
  }

  return (
    <tbody>
      {monthGridContext?.month.weeks.map((week, weekIndex) => {
        return (
          <tr style={{ paddingBottom: 4 }} key={weekIndex}>
            {week.map((day, index) => {
              if (!day) return <td key={`${weekIndex}--${index}`} />

              return (
                <td style={{ padding: 0 }} key={day.props.key}>
                  {props.children(day)}
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

interface WeekdaysProps {
  weekdays?: string[]
  children: (weekday: string) => React.ReactNode
}

export function Weekdays(props: WeekdaysProps) {
  const monthGridContext = useContext(MonthGridContext)

  if (!monthGridContext && process.env.NODE_ENV === 'development') {
    throw new Error('Must use <Weekdays /> component inside of a <MonthGrid />')
  }

  const weekdays = props.weekdays ?? monthGridContext?.month.weekdays ?? []

  return (
    <thead>
      <tr>
        {weekdays.map((weekday) => (
          <th key={weekday}>{props.children(weekday) ?? weekday}</th>
        ))}
      </tr>
    </thead>
  )
}

interface MonthHeaderProps {
  month: Month
  format?: string
}
export function MonthHeader(props: MonthHeaderProps) {
  return <div aria-live="polite">{props.month.firstDay.format(props.format ?? 'MMMM YYYY')}</div>
}

// Style Helpers

export function backgroundStyles(day: Day, backgroundColor = 'blue.50') {
  const styles = {
    [SelectionType.start]: `linear(90deg, white 50%, ${backgroundColor} 50%)`,
    [SelectionType.end]: `linear(90deg, ${backgroundColor} 50%,  white 50%)`,
    [SelectionType.middle]: 'transparent',
    [SelectionType.only]: 'transparent',
    [SelectionType.unselected]: 'transparent',
  }

  return {
    bgGradient: styles[day.selectionType],
  }
}

export function roundedDayStyles(day: Day, radius: number | string = '50%') {
  const styles = {
    [SelectionType.start]: radius,
    [SelectionType.end]: radius,
    [SelectionType.middle]: 0,
    [SelectionType.only]: radius,
    [SelectionType.unselected]: radius,
  }

  return {
    borderRadius: styles[day.selectionType],
  }
}

interface DayStylesOptions {
  backgroundColor?: string
  borderRadius?: string | number
  weekdayColor?: string
  weekendColor?: string
  todayColor?: string
}

export function dayStyles(day: Day, options: DayStylesOptions = {}): SystemStyleObject {
  const backgroundColor = options.backgroundColor ?? '#4070a4'
  const isSelectedDistinct = [SelectionType.start, SelectionType.end, SelectionType.only].includes(
    day.selectionType
  )

  return {
    ...backgroundStyles(day),

    button: {
      width: 42,
      height: 42,

      border: 'none',
      fontWeight: day.isToday ? 'bold' : 'normal',
      backgroundColor: 'transparent',
      color: day.isToday
        ? options.todayColor ?? backgroundColor
        : day.isWeekend
        ? options.weekendColor ?? '#777'
        : options.weekdayColor,
      ...(day.isSelected
        ? {
            color: isSelectedDistinct ? 'white' : undefined,
            backgroundColor: isSelectedDistinct ? 'blue.400' : backgroundColor,
          }
        : {}),
      ...roundedDayStyles(day, options.borderRadius ?? '50%'),
    },
  }
}
