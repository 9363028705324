import { ENV, isServer } from 'src/utils'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { useAuth } from 'src/auth'

/**
 * This is intentionally called at the root-leve of the module.
 * As long as this module is imported once somewhere, it will execute only once.
 */
if (!isServer() && ENV.ENABLE_ANALYTICS) {
  bootstrap()
}

export function bootstrap() {
  window._paq = window._paq ?? []

  if (!ENV.ENABLE_ANALYTICS) {
    console.log('[analytics] analytics is disabled')
    return
  }

  if (!ENV.BUSHEL_ENVIRONMENT) {
    console.log('[analytics] no environment')
    return
  }

  if (!ENV.MATOMO_SITE_ID) {
    console.log('[analytics] no site_id')
    return
  }

  window._paq.push(['setTrackerUrl', `${ENV.MATOMO_URL}/matomo.php`])
  window._paq.push(['setSiteId', ENV.MATOMO_SITE_ID])

  setCustomDimensions({
    environment: ENV.BUSHEL_ENVIRONMENT,
    versionId: ENV.APP_VERSION,
    ...getRuntimeDimensions(),
  })

  const d = document,
    g = d.createElement('script'),
    s = d.getElementsByTagName('script')[0]

  g.type = 'text/javascript'
  g.async = true
  g.defer = true
  g.src = ENV.MATOMO_URL + '/matomo.js'
  s?.parentNode?.insertBefore(g, s)
}

function getRuntimeDimensions() {
  if (isServer()) return {}

  return {
    slug: window?.__NEXT_DATA__?.props?.slug,
    isLoggedIn: !!window?.__NEXT_DATA__?.props?.user,
    userType: getUserType(window?.__NEXT_DATA__?.props?.user),
  }
}

function boolAsString(value: boolean) {
  return value ? 'TRUE' : 'FALSE'
}

type UserType = 'grower' | 'merchandiser'

interface SetCustomDimensionsProps {
  slug?: string
  isLoggedIn?: boolean
  environment?: string
  versionId?: string
  userType?: UserType
}

export function setCustomDimensions({
  slug,
  isLoggedIn,
  environment,
  versionId,
  userType,
}: SetCustomDimensionsProps) {
  if (!window?._paq) return

  if (slug) {
    window._paq.push(['setCustomDimension', 1, slug])
    window._paq.push(['setCustomDimension', 6, slug])
  }

  if (environment) {
    window._paq.push(['setCustomDimension', 2, environment])
    window._paq.push(['setCustomDimension', 7, environment])
  }

  if (versionId) {
    window._paq.push(['setCustomDimension', 3, versionId])
    window._paq.push(['setCustomDimension', 8, versionId])
  }

  if (typeof isLoggedIn !== 'undefined') {
    window._paq.push(['setCustomDimension', 4, boolAsString(isLoggedIn)])
    window._paq.push(['setCustomDimension', 9, boolAsString(isLoggedIn)])
  }

  if (typeof userType !== 'undefined') {
    window._paq.push(['setCustomDimension', 5, userType])
    window._paq.push(['setCustomDimension', 10, userType])
  }
}

export function trackPageView(pageName: string): void {
  if (window?._paq) {
    window._paq.push(['setDocumentTitle', pageName])
    window._paq.push(['trackPageView'])
  }
}

export function trackEvent(...rest: string[]) {
  const [category, action, eventName, eventValue] = rest

  if (window?._paq) {
    window._paq.push(['trackEvent', category, action, eventName, eventValue])
  }
}

export function getUserType(user: Me): UserType {
  if (!user) return undefined
  return user.user_type === 'staff' ? 'merchandiser' : 'grower'
}

export function setUserId(userID: string) {
  if (window?._paq && userID) {
    window._paq.push(['setUserId', userID])
    window._paq.push(['trackPageView'])
  }
}

export function resetUserId() {
  if (window?._paq) {
    window._paq.push(['resetUserId'])
    window._paq.push(['trackPageView'])
  }
}

// Track slug changes on route changes for Matomo tracking,
// will start a new visit if a different slug is detected on route change.
export const useMatomoTrackSlugChange = () => {
  const { events } = useRouter()
  const { initialized, slug } = useAuth()
  const previousCompanySlug = useRef(slug)

  useEffect(() => {
    if (!ENV.ENABLE_ANALYTICS) return

    const handleRouteChange = () => {
      if (initialized && slug !== previousCompanySlug.current) {
        previousCompanySlug.current = slug

        setCustomDimensions({
          environment: ENV.BUSHEL_ENVIRONMENT,
          versionId: ENV.APP_VERSION,
          ...getRuntimeDimensions(),
        })

        window?._paq?.push(['appendToTrackingUrl', 'new_visit=1']) // Forces a new visit
      } else {
        window?._paq?.push(['appendToTrackingUrl', '']) // Do not force a new visit anymore
      }
    }

    events.on('routeChangeComplete', handleRouteChange)

    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [events, initialized, slug])
}
