import type { AppContext } from 'next/app'
import type { IncomingMessage } from 'http'
import { getServerSession, isValidPersistableSlug } from 'src/auth'
import { authOptions } from 'src/pages/api/auth/[...nextauth]'
import isMobile from 'ismobilejs'
import {
  ENV,
  parseCookies,
  setCookie,
  getInstallationId,
  handleSsrRedirect,
  isClientRouteRequest,
} from 'src/utils'
import { AppConfig } from 'src/api/centre-service/data-models/app-config'

type Router = AppContext['router']
type NextPageContext = AppContext['ctx']

export const SLUG_COOKIE_NAME = 'bushel-web-company'

export function setLastSlugCookie(ctx: NextPageContext, slug: string) {
  setCookie(ctx, SLUG_COOKIE_NAME, slug)
}

export function getQueryCompanySlug(router: Router) {
  return router?.query?.company as string
}

export function getQueryUserId(router: Router) {
  return router?.query?.userId as string
}

/**
 * These are used to make various routing-related decisions within getInitialProps()
 */
export function getRouteFacts(route: string) {
  return {
    isRootRoute: route === '/',
    isAuthRoute: route.startsWith('/auth') || route.startsWith('/[company]/auth'),
    isSlugAccessRoute: route.indexOf('/[company]/access') === 0,
    isErrorRoute: route.indexOf('/_error') === 0,
    isWelcomeRoute: route.indexOf('/welcome') > 0,
    isNotificationsOnboardRoute: route.indexOf('/notifications-onboard') > 0,
    isCashBids: route.indexOf('/cash-bids') > 0,
    isFutures: route.indexOf('/futures') > 0,
    isSettings: route.indexOf('/settings') > 0,
    isPleaseClosePage: route.startsWith('/please-close'),
  }
}

export function getHintedBreakpoint(req?: IncomingMessage) {
  const result = isMobile(req?.headers?.['user-agent'])

  if (result.phone) return 'base'
  if (result.tablet) return 'md'

  return 'lg'
}

/**
 * This merges new data into our "props" object as goes through layers of logic.
 */
export function mergeProps(props: Record<string, any>, newProps: Record<string, any>) {
  Object.keys(newProps).forEach((key) => {
    props[key] = newProps[key]
  })
}

/**
 * Parses out a wide array of facts for server-side `getInitialProps` requests.
 * It starts with a Next `AppContext` and pulls everything out from there.
 * All parsed data and handlers are used to further validate and properly handle the request.
 */
export type ParsedRequestData = Awaited<ReturnType<typeof parseServerRequest>>
export type AppProps = ParsedRequestData['props']

export async function parseServerRequest(context: AppContext) {
  const { ctx, router, Component } = context
  const { req, res } = ctx
  // @ts-ignore
  const session = await getServerSession(req, res, authOptions)
  const route = router.route
  const cookies = parseCookies(ctx)
  const token = session?.accessToken
  const slug = getQueryCompanySlug(router) ?? cookies[SLUG_COOKIE_NAME]
  const selectedUserId = getQueryUserId(router)
  const installationId = getInstallationId(ctx, cookies)
  const breakpoint = getHintedBreakpoint(req)

  const props = {
    cookies,
    token,
    session,
    slug,
    installationId,
    breakpoint,
    version: ENV.APP_VERSION,
    user: undefined as unknown as Me,
    config: undefined as unknown as AppConfig,
    pageProps: undefined as unknown as Record<string, any>,
  }

  // _app ignores components' getInitialProps so we need to call manually
  if (Component.getInitialProps) {
    const pageProps = await Component.getInitialProps(ctx)
    mergeProps(props, { pageProps })
  }

  if (slug && isValidPersistableSlug(slug)) {
    // Implied slug, store cookie
    setLastSlugCookie(ctx, slug)
  }

  const buildLoggerContext = (rest?: Record<string, any>) => ({
    route,
    requestUrl: req?.url,
    companySlug: slug,
    installationId,
    version: ENV.APP_VERSION,
    ...rest,
  })

  return {
    req,
    res,
    route,
    token,
    session,
    slug,
    selectedUserId,
    props,
    installationId,
    isClientRouteRequest: isClientRouteRequest(req),
    logContext: buildLoggerContext(),
    buildLoggerContext,
    redirect: (path: string, redirectProps: Record<string, any>) =>
      handleSsrRedirect({ req, res, path, props: redirectProps }),
  }
}

/**
 * Parses out the bare-minimum of data for client-side `getInitialProps` requests.
 * The props it returns have to be maintained across pages.
 * It avoids doing any further data loading/parsing for things like flags
 */
export async function parseClientRequest(context: AppContext) {
  const { ctx, router } = context

  // For client routes we want to evalute the route they're navigating _to_.
  // Value of `router.route` is the current route, to get the target route, use `_inFlightRoute`
  const route = router._inFlightRoute
  const cookies = parseCookies(ctx)
  const slug = getQueryCompanySlug(router) ?? cookies[SLUG_COOKIE_NAME]

  return { cookies, slug, route }
}
