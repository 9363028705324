import { useMerchandiser } from 'src/data/merchandiser'
import { trackEvent } from 'src/utils/analytics'
import { Button, DocumentIcon, MenuItem } from 'src/components/designsystem'
import Link from 'next/link'
import { Ticket } from 'src/types/tickets/Ticket'

export function PdfViewLink({
  isActionMenu,
  slug,
  item,
  itemType,
}: Readonly<{
  isActionMenu?: boolean
  slug: string
  item: Contract | Settlement
  itemType: 'contract' | 'settlement'
}>) {
  const { userId } = useMerchandiser()
  const href = makePdfLink({ slug, userId, item, itemType })
  const pdfTypeCapitalized = itemType.charAt(0).toUpperCase() + itemType.slice(1).toLowerCase()
  const commonProps = {
    colorScheme: 'blue',
    'aria-label': 'View PDF',
    href,
    rel: 'noreferrer',
    target: '_blank',
    onClick: (e) => {
      trackEvent(pdfTypeCapitalized, 'View PDF')
      e.stopPropagation()
    },
  }

  if (isActionMenu) {
    return (
      <MenuItem
        as="a"
        href={href}
        target="_blank"
        rel="noreferrer"
        aria-label="View PDF"
        textStyle="body"
        icon={<DocumentIcon fontSize="xl" />}
        onClick={(e) => {
          trackEvent(pdfTypeCapitalized, 'View PDF')
          e.stopPropagation()
        }}
      >
        View PDF
      </MenuItem>
    )
  } else {
    return (
      <Button
        {...commonProps}
        as={Link}
        href={href}
        prefetch={false}
        leftIcon={<DocumentIcon fontSize="2xl" />}
        textStyle="body"
        fontWeight="normal"
        _active={{ backgroundColor: 'blue.400', color: 'white' }}
        variant="ghostSecondary"
        width="100%"
        justifyContent="flex-start"
      >
        View PDF
      </Button>
    )
  }
}

export function makePdfLink({
  slug,
  userId,
  item,
  itemType,
}: {
  slug: string
  userId?: string
  item: Contract | Settlement
  itemType: 'contract' | 'settlement'
}) {
  const base = userId ? `/${slug}/${userId}` : `/${slug}`
  return `${base}/${itemType}s/${item.id}/pdf`
}
