import { createContext, useContext } from 'react'
import ky from 'ky'
import { useAuthContext } from 'src/auth/auth-context'
import { useQuery } from '@tanstack/react-query'
import ENV from './env'
import logger from './logger'

// TYPES
///////////////////////////////////////////////////////////////////////////////////////////////////

export interface FlagrResponse {
  evalContext: {
    entityContext: Record<string, string>
    entityID: string
    flagKey: string
    enableDebug: boolean
    entityType: string
  }
  flagID: number
  flagKey: string
  flagSnapshotID: number
  segmentID: number
  timestamp: string
  variantAttachment: Record<string, any>
  variantID: number
  variantKey: string
}

interface FlagArgs {
  flagKey: string
  variantKey?: string
  companySlug: string
  context?: { [key: string]: string }
}

export type GlobalFlagsResults = Record<(typeof GLOBAL_FLAGS)[number] | any, boolean | string>

export interface FlagrContextValue {
  setFlags: (args: GlobalFlagsResults) => void
  flags: GlobalFlagsResults
}

// CONSTS / HELPERS
///////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Flags are just identified by a string value.
 * Please reference this authoritative export for any usages.
 */
export const FLAG_KEYS = {
  WEBAPP_SETTLEMENTS_PDF: 'webapp_settlements_pdf',
  WEBAPP_CONTRACTS_PDF: 'webapp_contracts_pdf',
  WEBAPP_TICKET_DOWNLOAD_INDIVIDUAL_PDF: 'webapp_ticket_download_individual_pdf',
  WEBAPP_PDF_SETTLEMENT_V2: 'web_portal_pdf_settlement_v2',
  WEBAPP_TICKET_APPLICATIONS_V2: 'webapp_ticket_applications_v2',
  WEBAPP_ESIGN_TOKEN: 'webapp_esign_token',
  WEBAPP_STAFF_ROUTES: 'webapp_staff_routes',
  WEBAPP_TICKET_FILTER: 'web_portal_tickets_forced_date_filter_months',
  WEBAPP_TICKETS_SIMPLE_PAGINATION: 'web_portal_tickets_simple_pagination',
  WEBAPP_MAKE_OFFER: 'web_portal_make_offer',
  WEBAPP_EDIT_OFFER: 'web_portal_edit_offer',
  WEBAPP_MY_OFFERS: 'web_portal_my_offers',
  WEBAPP_MY_OFFERS_FILTERS: 'web_portal_my_offers_filters',
  WEBAPP_EXTERNAL_PAYMENTS_SUMMARY: 'web_portal_external_payment_summary',
  WEBAPP_EXTERNAL_PAYMENT_FILTERS: 'web_portal_external_payment_filters',
  WEBAPP_EXTERNAL_PAYMENT_ASSOCIATIONS: 'web_portal_external_payment_associations',
  WEBAPP_EXTERNAL_POY_EXPORT: 'web_portal_poy_export',
  WEBAPP_STAFF_PAYABLE: 'web_portal_staff_payable',
  WEBAPP_STAFF_RECEIVABLE: 'web_portal_staff_receivable',
  WEBAPP_STAFF_DIRECT_RECEIVABLE: 'web_portal_staff_direct_receivable',
  WEBAPP_STAFF_TRADE: 'web_portal_staff_trade',
  WEBAPP_STAFF_FULFILLMENT: 'web_portal_staff_fulfillment',
  WEBAPP_STAFF_WEBSITE: 'web_portal_staff_website',
  WEBAPP_STAFF_KNOWLEDGE_BASE: 'web_portal_staff_knowledge_base',
  WEBAPP_TICKET_APPLICATIONS_EXPORT: 'web_portal_ticket_applications_export',
  WEBAPP_CONTRACTS_EXPORT: 'web_portal_contracts_export',
  WEBAPP_INVOICE_FILTERS: 'web_portal_invoice_filters',
  WEBAPP_WALLET_LINK_BANNER: 'web_portal_wallet_link_banner',
  WEBAPP_DIRECT_DEPOSIT_LINKING: 'web_portal_direct_deposit_linking',
  WEBAPP_INTERCOM: 'web_portal_intercom',
  WEBAPP_SETTINGS_PAGE: 'web_portal_settings_page',
  WEBAPP_INVOICE_PAYMENTS_APPLIED: 'web_portal_invoice_payments_applied',
  WEBAPP_PAYMENT_FUNDING_SOURCE: 'web_portal_payment_funding_source',
  MAKE_PAYMENT_DEVICE_SECURITY: 'web_portal_make_payment_device_security',
  WEBAPP_REFRESH_TOKEN_IF_EXPIRED: 'web_portal_handle_refresh_token_if_expired',
  WEBAPP_SIGN_IN_REQUIRED_SCREEN: 'web_portal_sign_in_required_screen',
  WEBAPP_CASH_BID_CONTACT_INFORMATION: 'web_portal_show_cash_bid_contact_information',

  // Features
  WEBAPP_FEATURE_ANALYTICS: makeFeatureFlagKey('analytics'),
  WEBAPP_FEATURE_EXTERNAL_PAYMENTS: makeFeatureFlagKey('payments_external'),
  WEBAPP_STATEMENTS: makeFeatureFlagKey('web_portal_statements'),
  WEBAPP_WORK_ORDERS: makeFeatureFlagKey('work_orders'),
  WEBAPP_OUTBOUND_TICKETS: makeFeatureFlagKey('outbound_tickets'),

  // Scoular
  WEBAPP_CONTRACTS_PDF_USER_ENABLED: 'webapp_contracts_pdf_user_enabled',
}

/**
 * "Global" flags are fetched server-side.
 * When adding new flags to be evaluated within the app, it can likely just be added to this list.
 */
export const GLOBAL_FLAGS = [
  FLAG_KEYS.WEBAPP_SETTLEMENTS_PDF,
  FLAG_KEYS.WEBAPP_CONTRACTS_PDF,
  FLAG_KEYS.WEBAPP_TICKET_DOWNLOAD_INDIVIDUAL_PDF,
  FLAG_KEYS.WEBAPP_TICKET_APPLICATIONS_V2,
  FLAG_KEYS.WEBAPP_ESIGN_TOKEN,
  FLAG_KEYS.WEBAPP_STAFF_ROUTES,
  FLAG_KEYS.WEBAPP_MAKE_OFFER,
  FLAG_KEYS.WEBAPP_EDIT_OFFER,
  FLAG_KEYS.WEBAPP_MY_OFFERS,
  FLAG_KEYS.WEBAPP_MY_OFFERS_FILTERS,
  FLAG_KEYS.WEBAPP_EXTERNAL_PAYMENT_FILTERS,
  FLAG_KEYS.WEBAPP_EXTERNAL_PAYMENT_ASSOCIATIONS,
  FLAG_KEYS.WEBAPP_EXTERNAL_POY_EXPORT,
  FLAG_KEYS.WEBAPP_TICKET_APPLICATIONS_EXPORT,
  FLAG_KEYS.WEBAPP_CONTRACTS_EXPORT,
  FLAG_KEYS.WEBAPP_INVOICE_FILTERS,
  FLAG_KEYS.WEBAPP_WALLET_LINK_BANNER,
  FLAG_KEYS.WEBAPP_DIRECT_DEPOSIT_LINKING,
  FLAG_KEYS.WEBAPP_SETTINGS_PAGE,
  FLAG_KEYS.WEBAPP_TICKETS_SIMPLE_PAGINATION,
  FLAG_KEYS.WEBAPP_STATEMENTS,
  FLAG_KEYS.WEBAPP_PAYMENT_FUNDING_SOURCE,
  FLAG_KEYS.WEBAPP_INVOICE_PAYMENTS_APPLIED,
  FLAG_KEYS.MAKE_PAYMENT_DEVICE_SECURITY,
  FLAG_KEYS.WEBAPP_REFRESH_TOKEN_IF_EXPIRED,
  FLAG_KEYS.WEBAPP_SIGN_IN_REQUIRED_SCREEN,
  FLAG_KEYS.WEBAPP_CASH_BID_CONTACT_INFORMATION,

  // Features
  FLAG_KEYS.WEBAPP_FEATURE_ANALYTICS,
  FLAG_KEYS.WEBAPP_FEATURE_EXTERNAL_PAYMENTS,
  FLAG_KEYS.WEBAPP_EXTERNAL_PAYMENTS_SUMMARY,
  FLAG_KEYS.WEBAPP_WORK_ORDERS,
  FLAG_KEYS.WEBAPP_OUTBOUND_TICKETS,
  // The `6` here isn't important, but is needed for the Flagr logic to work
  [FLAG_KEYS.WEBAPP_TICKET_FILTER, '6'],
  FLAG_KEYS.WEBAPP_STAFF_PAYABLE,
  FLAG_KEYS.WEBAPP_STAFF_RECEIVABLE,
  FLAG_KEYS.WEBAPP_STAFF_DIRECT_RECEIVABLE,
  FLAG_KEYS.WEBAPP_STAFF_TRADE,
  FLAG_KEYS.WEBAPP_STAFF_FULFILLMENT,
  FLAG_KEYS.WEBAPP_STAFF_WEBSITE,
  FLAG_KEYS.WEBAPP_STAFF_KNOWLEDGE_BASE,
  FLAG_KEYS.WEBAPP_INTERCOM,
] as const

/**
 * Variant keys can be anything, but by convention boolean checks use
 * a value of `enabled`.
 */
const DEFAULT_VARIANT_KEY = 'enabled'

export const FlagrContext = createContext<FlagrContextValue | null>(null)

export const flagrClient = ky.create({ prefixUrl: ENV.FLAGR_API, retry: 0 })

/**
 * Defines the naming convention of _feature_ flags which can override
 * company config.
 *
 * Examples:
 *  - webapp_feature_cash_bids
 *  - webapp_feature_tickets
 *  - etc.
 */
function makeFeatureFlagKey(key: string) {
  return `webapp_feature_${key}`
}

/**
 * An "entity context" is used to provide the contextual information that Flagr
 * uses to perform its evaluation.
 * @deprecated replaced by `getConfigCatUser`
 */
function makeEntityContext({
  companySlug,
  context,
}: {
  companySlug: string
  context?: Record<string, any>
}) {
  return {
    env: ENV.BUSHEL_ENVIRONMENT,
    version: ENV.APP_VERSION,
    company_slug: companySlug,
    ...context,
  }
}

/**
 * @deprecated replaced by `evaluateAllFlagsSSR`
 */
export async function loadGlobalFlags({ companySlug }) {
  return flagBatch<typeof GLOBAL_FLAGS>({ flagKeys: GLOBAL_FLAGS, companySlug })
}

// HOOKS
///////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @deprecated src/utils/config-cat/hooks/useFeatureFlag.ts replaces this hook
 */
export function useGlobalFlagr() {
  const ctx = useContext(FlagrContext)

  return {
    ...ctx,
    isFeatureFlagEnabled: (featureKey: string) => {
      const flagConfigValue = ctx?.flags?.[makeFeatureFlagKey(featureKey)]

      // If the feature isn't defined by a flag, consider it ENABLED
      return typeof flagConfigValue === 'undefined' || flagConfigValue
    },
  }
}

/**
 * @deprecated src/utils/config-cat/hooks/useFeatureFlag.ts replaces this hook
 */
export function useGlobalFlag<T = boolean>(key: string, castAsBoolean = true): T {
  const { flags } = useGlobalFlagr()

  if (castAsBoolean) {
    return Boolean(flags?.[key]) as unknown as T
  }

  return flags?.[key] as T
}

/**
 * @deprecated src/utils/config-cat/hooks/useFeatureFlag.ts replaces this hook
 */
export function useFlagr<EvalType = boolean>(
  flagKey: string,
  options: { variantKey?: string; defaultValue?: EvalType; context?: Record<string, any> } = {}
) {
  const { variantKey, defaultValue, context } = options
  const { slug: companySlug, user } = useAuthContext()
  const userId = String(user?.id)

  const flagQuery = useQuery({
    queryKey: ['flags-hook', { flagKey, variantKey, context, companySlug, userId }],
    queryFn: async () => {
      return flag({
        flagKey,
        variantKey,
        companySlug,
        context: { userId, ...context },
      })
    },
    staleTime: Infinity,
  })

  return flagQuery.isSuccess ? flagQuery.data : defaultValue
}

// SINGLE EVALUATE
///////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @deprecated unused, can be replaced with `evaluateFlagSSR`
 */
export const flagrEvaluate = async (
  body: Record<string, any>
): Promise<[Error, null] | [null, FlagrResponse]> => {
  try {
    const response = await flagrClient.post('evaluation', { json: body }).json<FlagrResponse>()

    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

/**
 * @deprecated unused, can be replaced with `evaluateFlagSSR`
 */
export const flag = async ({
  flagKey,
  variantKey = DEFAULT_VARIANT_KEY,
  companySlug,
  context,
}: FlagArgs) => {
  const [err, response] = await flagrEvaluate({
    flagKey,
    entityContext: makeEntityContext({ companySlug, context }),
  })

  if (err) {
    logger.error({
      message: `flagr error during evaluation ${err}`,
      context: { companySlug, flagKey, env: ENV.BUSHEL_ENVIRONMENT, variantKey },
    })
    return false
  }

  return response?.variantKey === variantKey
}

// BATCH EVALUATION
///////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @deprecated `evaluateAllFlagsSSR` can replace this hook if needed
 */
export const flagrEvaluateBatch = async (
  body: Record<string, any>
): Promise<[Error, null] | [null, FlagrResponse[]]> => {
  try {
    const response = await flagrClient.post('evaluation/batch', { json: body })
    const responseBody: { evaluationResults: FlagrResponse[] } = await response.json()

    return [null, responseBody.evaluationResults]
  } catch (err) {
    return [err, null]
  }
}

/**
 * When specifying `flagKeys` to flagBatch(), they can be formatted as `string[]` or `FlagTuple[]`.
 * If a `string[]` of keys is provided, the `DEFAULT_VARIANT_KEY` ("enabled") will be used.
 * Otherwise, use a `FlagTuple` to specify the `variantKey` that is used to evaluate.
 *
 * Examples (equivalent):
 *  - ['key_1', 'key_2]
 *  - [['key_1', 'enabled'], ['key_2', 'enabled']]
 *
 * Example of custom `variantKey`:
 *  - [['key_1', 'variant_1'], ['key_2', 'variant_2']]
 */
export type FlagTuple = Readonly<[string, string?]>
export type FlagKey = string | FlagTuple

function isFlagKeyTuple(flagKey: any): flagKey is FlagTuple {
  return Array.isArray(flagKey)
}

interface FlagBatchArgs {
  flagKeys: Readonly<FlagKey[]>
  companySlug: string
  context?: Record<string, any>
}

/**
 * @deprecated `evaluateAllFlagsSSR` can replace this hook if needed
 */
export async function flagBatch<Keys>({ flagKeys, companySlug, context = {} }: FlagBatchArgs) {
  const [err, response] = await flagrEvaluateBatch({
    flagKeys: flagKeys.map((flagKey) => (isFlagKeyTuple(flagKey) ? flagKey[0] : flagKey)),
    // You _can_ specify multiple entities, but we should only ever need one
    entities: [{ entityContext: makeEntityContext({ companySlug, context }) }],
  })

  if (err) {
    logger.error({
      message: `flagr error during evaluation ${err}`,
      context: { companySlug, flagKeys, env: ENV.BUSHEL_ENVIRONMENT },
    })
  }

  return flagKeys.reduce((acc, flagKeyArg) => {
    const [flagKey, variantKey = DEFAULT_VARIANT_KEY] = isFlagKeyTuple(flagKeyArg)
      ? flagKeyArg
      : [flagKeyArg]
    const flagResult = response?.find((result) => result.flagKey === flagKey)

    return {
      ...acc,
      [flagKey]:
        DEFAULT_VARIANT_KEY === variantKey
          ? flagResult?.variantKey === variantKey
          : flagResult?.variantKey ?? null,
    }
  }, {}) as Record<keyof Keys, boolean>
}
